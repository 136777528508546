import axios from "axios";
import { categoryList } from "const/category";
import { Board } from "const/table";
import { useEffect, useState } from "react";

const Portfolio = () => {
  const [data, setData] = useState<Board[] | null>(null);

  useEffect(() => {
    axios
      .get(`/api/board?page=0&size=4`)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
        }
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
      <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
        <div className=" relative w-full h-fit mb-10 text-left">
          <small className=" font-black">Portfolio</small>
          <h3>콘크리트 코아채취/코어작업/철근부식 전문업체</h3>
        </div>
        <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 grid-rows-2 lg:grid-rows-1 gap-5">
          {data &&
            data.length > 0 &&
            data.map((v, i) => (
              <div
                data-aos="fade-up"
                data-aos-delay={i * 100}
                key={i}
                className="mb-3 cursor-pointer aos-init aos-animate"
                onClick={() => (window.location.href = `/portfolio/${v.id}`)}
              >
                <div className=" relative w-full h-44 lg:h-52 xl:h-72 bg-gray-200">
                  <div className=" absolute left-0 top-0 w-full h-full grid items-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all duration-300 z-10">
                    <div className=" text-white relative w-fit h-fit mx-auto animate-bounce">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        stroke="none"
                        className="tabler-icon tabler-icon-pointer-filled "
                      >
                        <path d="M3.039 4.277l3.904 13.563c.185 .837 .92 1.516 1.831 1.642l.17 .016a2.2 2.2 0 0 0 1.982 -1.006l.045 -.078l1.4 -2.072l4.05 4.05a2.067 2.067 0 0 0 2.924 0l1.047 -1.047c.388 -.388 .606 -.913 .606 -1.461l-.008 -.182a2.067 2.067 0 0 0 -.598 -1.28l-4.047 -4.048l2.103 -1.412c.726 -.385 1.18 -1.278 1.053 -2.189a2.2 2.2 0 0 0 -1.701 -1.845l-13.524 -3.89a1 1 0 0 0 -1.236 1.24z"></path>
                      </svg>
                    </div>
                  </div>
                  <div className=" absolute right-2 bottom-2 w-fit h-fit z-20">
                    <small className="flex items-center gap-1 font-black text-white">
                      {categoryList
                        .filter((cate) => cate.id === v.categoryId)
                        .map((cate) => cate.title)}
                    </small>
                  </div>
                  <img
                    className=" relative w-full h-full object-cover object-center"
                    src={
                      v.thumb != null
                        ? v.thumb
                        : v.images && v.images.length > 0
                        ? v.images[0].src
                        : "/images/business11.jpg"
                    }
                    alt="thumb"
                  />
                </div>

                <p>{v.title}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
