import axios from "axios";
import { dateFormat } from "const/date";
import { Contact } from "const/table";
import { useEffect, useState } from "react";

const InquiryList = () => {
  const [data, setData] = useState<Contact[] | null>(null);

  useEffect(() => {
    axios
      .get(`/api/contact?page=0&size=10`)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
        }
      })
      .catch((e) => console.error(e));
  }, []);
  return (
    <div
      onClick={() => (window.location.href = "/admin/inquiry")}
      className="relative w-full h-full bg-white border shadow-md py-5 px-4 rounded-lg cursor-pointer my-3"
    >
      <div className="mb-3 lg:mb-5">
        <small className="text-base">온라인문의</small>
      </div>
      {data &&
        data.length > 0 &&
        data.map((v, i) => (
          <div
            key={i}
            className=" relative w-full h-fit grid grid-cols-9 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
          >
            <p>{v.id}</p>
            <p className="col-span-2 ellipsis">{v.name}</p>
            <p className="col-span-3 ellipsis">{v.phone}</p>
            <p className="col-span-3 ellipsis">{dateFormat(v.created)}</p>
          </div>
        ))}
    </div>
  );
};

export default InquiryList;
