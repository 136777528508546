import SubTab from "components/business/SubTab";
import SubTitle from "components/common/sub/SubTitle";

const Business3 = () => {
  const processArr = [
    {
      id: 1,
      title: "부재 철근 탐사",
      icon: "/icons/icon1.png",
    },
    {
      id: 2,
      title: "작업 위치 선정",
      icon: "/icons/icon2.png",
    },
    {
      id: 3,
      title: "보양 후 콘크리트 홈파기",
      icon: "/icons/icon3.png",
    },
    {
      id: 4,
      title: "철근 절단 후 수거",
      icon: "/icons/icon4.png",
    },
    {
      id: 5,
      title: "동일 직경 철근 결속 후 작업 부위 원상복구",
      icon: "/icons/icon5.png",
    },
    {
      id: 6,
      title: "수거된 철근 연구소 접수",
      icon: "/icons/icon7.png",
    },
    {
      id: 7,
      title: "시험성적서 송부",
      icon: "/icons/icon8.png",
    },
  ];
  return (
    <div className=" relative w-full h-full">
      <SubTitle
        category="업무분야"
        title="콘크리트 파취 후 철근 채취"
        src="/images/sub13.jpg"
      />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div id="title">
            <h3>철근 인장 및 항복강도 시험 KS D 3504</h3>
          </div>
          <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
            <p>
              구조물 설계에 있어서 재료가 적절하게 기능을 수행하는지 알기 위해
              재료의 기계적 거동에 대한 이해를 필요로 한다. 이를 위해 시험을
              시행한다.
            </p>
            <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business31.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business32.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business33.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business34.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business35.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business36.jpg"
                  alt="business"
                />
              </div>
            </div>
            <h3>평가 기준</h3>
            <p>
              철근콘크리트용 봉강(철근)의 시험 시료는 통상 60cm 길이를 기준으로
              3개를 1 개 규격 시험의 시료로 시험을 시행하며, 항복강도, 인장강도
              및 연신율, 굽힘 성 능, 화학성분(P, S, Si), 겉모양, 치수(마디 높이,
              마디 간격), 단위 무게 등을 시험 하고 성능을 확인한다.
            </p>
          </div>
        </div>
      </div>
      <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <h3>진행 프로세스</h3>
          <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-7 lg:grid-rows-3 gap-10 mt-10">
            {processArr.map((v, i) => (
              <div
                key={i}
                className=" relative w-full h-full bg-white py-10 px-8 rounded-sm"
              >
                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                  <p className="font-black">STEP 0{v.id}</p>
                </div>
                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                  <img
                    className=" relative w-full h-full object-contain"
                    src={process.env.PUBLIC_URL + v.icon}
                    alt="icon"
                  />
                </div>
                <h4>{v.title}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business3;
