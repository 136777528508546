import axios from "axios";
import SubTitle from "components/common/sub/SubTitle";
import { categoryList } from "const/category";
import { dateFormat } from "const/date";
import { Board } from "const/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const PortfolioDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState<Board | null>(null);

  useEffect(() => {
    axios
      .get(`/api/board/${params.id}`)
      .then((res) => {
        if (res.data.result === "0000") setData(res.data.data);
        else
          toast.error(res.data.data, {
            onClose: () => (window.location.href = "/portfolio"),
          });
      })
      .catch((e) =>
        toast.error(e, {
          onClose: () => (window.location.href = "/portfolio"),
        })
      );
  }, [params.id]);

  return (
    <div className=" relative w-full h-full">
      <SubTitle category="주요실적" title="주요실적" src="/images/sub11.jpg" />
      {data && (
        <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] mx-auto py-14 lg:py-28">
          <div className=" relative w-full h-full my-5 lg:my-10">
            <h4 className="text-center py-7 lg:py-14">{data.title}</h4>
            <div className="relative w-full h-full bg-[var(--gray-color)] px-2 py-3 flex justify-between rounded-sm">
              <small className="flex items-center gap-1 whitespace-nowrap overflow-hidden overflow-ellipsis">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="tabler-icon tabler-icon-category "
                >
                  <path d="M4 4h6v6h-6z"></path>
                  <path d="M14 4h6v6h-6z"></path>
                  <path d="M4 14h6v6h-6z"></path>
                  <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                </svg>
                {categoryList
                  .filter((cate) => cate.id === data.categoryId)
                  .map((cate) => cate.title)}
              </small>
              <small className="flex items-center gap-1 whitespace-nowrap">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="tabler-icon tabler-icon-clock-hour-1 "
                >
                  <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                  <path d="M12 7v5"></path>
                  <path d="M12 12l2 -3"></path>
                </svg>
                {dateFormat(data.created)}&nbsp; &nbsp; &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="tabler-icon tabler-icon-eye "
                >
                  <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                  <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6"></path>
                </svg>
                6
              </small>
            </div>
            <div className="relative w-full h-full px-2 py-5 lg:py-10">
              <div
                className="contents"
                dangerouslySetInnerHTML={{ __html: data.contents }}
              ></div>
            </div>
          </div>
          <div className="relative w-full h-fit max-w-96 mx-auto my-14 lg:my-28">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className=" relative w-full h-fit bg-[var(--gray-color)] py-3 rounded-sm"
            >
              <p className="font-black">목록으로</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioDetail;
