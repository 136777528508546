import ContactUs from "components/home/ContactUs";
import Banner from "../../components/home/Banner";
import Portfolio from "components/home/Portfolio";
import Service from "components/home/Service";

const Home = () => {
  return (
    <div>
      <Banner />
      <Service />
      <ContactUs />
      <Portfolio />
    </div>
  );
};

export default Home;
