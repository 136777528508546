import SubTitle from 'components/common/sub/SubTitle';
import React from 'react';

const Greetings = () => {
    return (
        <div className=" relative w-full h-full">
            <SubTitle category="회사소개" title="인사말" src="/images/sub1.jpg" />
            <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
                <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
                    <div id="title">
                        <div className=" relative w-8 h-1 bg-[var(--point-color)] mb-5"></div>
                        <p className="font-black opacity-55">Greetings</p>
                        <h3>내외이앤씨 홈페이지 방문을 진심으로 환영합니다.</h3>
                    </div>
                    <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
                        <p>
                            당사는 안전진단(내진성능평가) 및 구조엔지니어링 업무와 관련된 콘크리트 코어채취 및 콘크리트
                            파취 후 철근 채취, 철근 부식도 조사, 코어작업 등의 업무를 주로 수행하고 있으며,
                            <br /> 공공시설 및 학교, 공공청사, 공동주택, 각종 시설물 등 수많은 프로젝트를 전문적인
                            기술로 선도해온 기업입니다.
                        </p>
                        <div className=" relative w-full h-fit grid lg:grid-cols-2 gap-5">
                            <div className=" relative w-full h-52 lg:h-[400px]">
                                <img
                                    className=" relative w-full h-full object-cover object-center"
                                    src={process.env.PUBLIC_URL + '/images/about1.jpg'}
                                    alt="about"
                                />
                            </div>
                            <p className="lg:hidden">
                                또한 최신식 장비와 knowhow로 안전(Safety), 품질(Quality), 신뢰 (Trust)를 바탕으로 고객
                                만족을 위해 항상 최선을 다하겠습니다.
                            </p>
                            <div className=" relative w-full h-52 lg:h-[400px]">
                                <img
                                    className=" relative w-full h-full object-cover object-center"
                                    src={process.env.PUBLIC_URL + '/images/about2.jpg'}
                                    alt="about"
                                />
                            </div>
                        </div>
                        <p className="hidden lg:block">
                            또한 최신식 장비와 Knowhow로 안전(Safety), 품질(Quality), 신뢰 (Trust)를 바탕으로 고객
                            만족을 위해 항상 최선을 다하겠습니다.
                        </p>
                        <p>내외이앤씨를 찾아주신 모든 고객님께 감사드립니다.</p>
                    </div>
                    {/* <div>관리자모드 id. inoutenc / pw. inout0803!</div> */}
                </div>
            </div>
        </div>
    );
};

export default Greetings;
