import SubTitle from "components/common/sub/SubTitle";

const Business4 = () => {
  const processArr = [
    {
      id: 1,
      title: "부재 철근 탐사",
      icon: "/icons/icon1.png",
    },
    {
      id: 2,
      title: "작업 위치 선정",
      icon: "/icons/icon2.png",
    },
    {
      id: 3,
      title: "보양 후 코어채취",
      icon: "/icons/icon3.png",
    },
    {
      id: 4,
      title: "콘크리트 제거 후 철근 노출",
      icon: "/icons/icon4.png",
    },
    {
      id: 5,
      title: "조사 완료 후 작업 부위 원상복구",
      icon: "/icons/icon5.png",
    },
    {
      id: 6,
      title: "현장 주변 정리",
      icon: "/icons/icon6.png",
    },
  ];
  return (
    <div className=" relative w-full h-full">
      <SubTitle
        category="업무분야"
        title="철근 부식도 조사"
        src="/images/sub7.jpg"
      />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div id="title">
            <h3>철근 부식도 조사</h3>
          </div>
          <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
            <p>
              외부 환경 또는 구조물 자체적 원인으로 발생 되는 콘크리트 내의 철근
              부식의 유무를 평가하기 위하여 실시한다.
            </p>
            <div className=" relative w-full h-96 lg:h-[550px] bg-gray-300 grid grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-2 gap-1">
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business41.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business42.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business43.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business44.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business45.jpg"
                  alt="business"
                />
              </div>
              <div className=" relative w-full h-full overflow-hidden">
                <img
                  className=" relative w-full h-full object-cover object-center"
                  src="/images/business46.jpg"
                  alt="business"
                />
              </div>
            </div>
            <h3>평가 기준</h3>
            <p>
              철근의 녹은 표면에 산화막을 형성시키며, 하중 및 외력 등에 의해
              산화막이 파괴되면 녹의 진행이 촉진되고 철근 단면이 감소 되므로
              노출 철근의 종류조사, 녹발생 범위조사, 콘크리트의 중성화 조사로
              시험을 시행한다.
            </p>
          </div>
        </div>
      </div>
      <div className=" relative w-full h-full bg-[var(--light-color)] px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <h3>진행 프로세스</h3>
          <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-3 grid-rows-6 lg:grid-rows-2 gap-10 mt-10">
            {processArr.map((v, i) => (
              <div
                key={i}
                className=" relative w-full h-full bg-white py-10 px-8 rounded-sm"
              >
                <div className=" absolute top-[-18px] left-3 w-fit h-fit py-1 px-5 bg-[var(--point-color)] text-white rounded-full">
                  <p className="font-black">STEP 0{v.id}</p>
                </div>
                <div className=" relative w-10 h-10 lg:w-14 lg:h-14 mb-5">
                  <img
                    className=" relative w-full h-full object-contain"
                    src={process.env.PUBLIC_URL + v.icon}
                    alt="icon"
                  />
                </div>
                <h4>{v.title}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business4;
