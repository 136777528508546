import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MNav from "./MNav";

const MHeader = () => {
  const [one, setOne] = useState(false);
  const [two, settwo] = useState(false);
  const [header, setheader] = useState(false);

  const [onNav, setOnNav] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    !pathname.includes("transform") &&
      window.addEventListener("scroll", () => {
        let nextScrollTop = window.scrollY;

        if (nextScrollTop > 100) setheader(true);
        if (nextScrollTop < 99) setheader(false);
      });
  }, []);

  useEffect(() => {
    if (pathname.includes("transform")) setheader(true);
  }, [pathname]);

  return (
    <header
      className={`fixed left-0 top-0 right-0 w-full h-fit transition-all duration-200 ${
        header ? "text-[var(--font-color)]" : "text-white"
      } z-30 px-[calc((100%-var(--container))/2)] ${
        header ? "bg-white bg-opacity-95" : "bg-transparent bg-opacity-0"
      }`}
    >
      <div className=" relative w-full max-w-[var(--container-width)] h-[var(--header-height)] flex justify-between items-center mx-auto">
        <div
          className=" relative w-fit h-full max-h-8 lg:max-h-14"
          onClick={() => {
            setOnNav(false);
            navigate("/");
          }}
        >
          {header ? (
            <img
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              className=" relative w-full h-full object-contain cursor-pointer"
            />
          ) : (
            <img
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/white_logo.png"}
              className=" relative w-full h-full object-contain cursor-pointer"
            />
          )}
        </div>
        <div className="hidden relative w-full h-full lg:flex items-center justify-end">
          <ul className=" relative w-4/5 h-fit flex justify-end items-center">
            <li
              id="menu1"
              className=" relative text-[20px] font-semibold py-2 px-12 cursor-pointer"
              onMouseEnter={() => setOne(true)}
              onMouseLeave={() => setOne(false)}
            >
              회사소개
              <ul
                className={
                  one
                    ? "menu1 absolute left-0 top-10 bg-white text-[var(--font-color)] shadow-sm min-w-[150px] py-4 rounded-lg on"
                    : "menu1 absolute left-0 top-10 bg-white text-[var(--font-color)] shadow-sm min-w-[150px] py-4 rounded-lg  hidden opacity-0"
                }
              >
                <li
                  className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/about/greetings");
                  }}
                >
                  인사말
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/about/equipments");
                  }}
                >
                  장비보유현황
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-6 cursor-pointer"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/about/location");
                  }}
                >
                  오시는 길
                </li>
              </ul>
            </li>
            <li
              id="menu2"
              className="relative text-[20px] font-semibold py-2 px-12 cursor-pointer"
              onMouseEnter={() => settwo(true)}
              onMouseLeave={() => settwo(false)}
            >
              업무분야
              <ul
                className={
                  two
                    ? "menu2 absolute left-0  top-10 bg-white text-[var(--font-color)] shadow-sm min-w-[235px] py-4 rounded-lg on"
                    : "menu2 absolute left-0  top-10 bg-white text-[var(--font-color)] shadow-sm min-w-[235px] py-4 rounded-lg hidden opacity-0"
                }
              >
                <li
                  className="text-[17px] font-semibold py-2 px-5"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/business/1");
                  }}
                >
                  콘크리트 코어채취
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-5"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/business/3");
                  }}
                >
                  콘크리트&nbsp;파취&nbsp;후&nbsp;철근&nbsp;채취
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-5"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/business/4");
                  }}
                >
                  철근 부식도 조사
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-5"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/business/5");
                  }}
                >
                  반발경도 측정 면 제거
                </li>
                <li
                  className="text-[17px] font-semibold py-2 px-5"
                  onClick={() => {
                    setOnNav(false);
                    navigate("/business/6");
                  }}
                >
                  코어작업
                </li>
              </ul>
            </li>
            <li
              className="text-[20px] font-semibold py-2 px-12 cursor-pointer"
              onClick={() => {
                setOnNav(false);
                navigate("/portfolio");
              }}
            >
              주요실적
            </li>
            <li
              className="text-[20px] font-semibold py-2 px-12 cursor-pointer"
              onClick={() => {
                setOnNav(false);
                navigate("/contact");
              }}
            >
              온라인문의
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="block lg:hidden"
          onClick={() => setOnNav(true)}
        >
          {header ? (
            <img
              alt="burger"
              src={process.env.PUBLIC_URL + "/icons/burger.svg"}
              width={25}
              height={25}
            />
          ) : (
            <img
              alt="burger"
              src={process.env.PUBLIC_URL + "/icons/white_burger.svg"}
              width={25}
              height={25}
            />
          )}
        </button>
      </div>
      <MNav onNav={onNav} setOnNav={setOnNav} />
    </header>
  );
};

export default MHeader;
