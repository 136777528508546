import KakaoMap from "components/about/KakaoMap";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  return (
    <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-7 lg:py-14 bg-[var(--point-color)] text-white">
      <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
        <div className=" relative w-full h-fit mb-10 text-center">
          <small className=" font-black">Contact us</small>
          <h3>신뢰를 바탕으로 고객과 소통하는 내외이앤씨</h3>
        </div>
        <div className=" relative w-full h-full grid grid-cols-1 lg:grid-cols-2 grid-rows-2 lg:grid-rows-1 gap-10">
          <div
            data-aos="fade-right"
            className=" relative w-full h-72 lg:h-80 xl:h-96"
          >
            <KakaoMap />
          </div>
          <div
            data-aos="fade-left"
            className=" relative w-full h-full lg:grid lg:py-5"
          >
            <h4>내외이앤씨</h4>
            <div className=" relative w-full h-fit grid gap-3 my-5 lg:my-10">
              <div className="flex items-start gap-3">
                <img
                  src={process.env.PUBLIC_URL + "/icons/icon_clock.svg"}
                  alt="location"
                  width={20}
                  height={20}
                  className="mt-1"
                />
                <p>운영시간 : 9시 ~ 19시</p>
              </div>
              <div className="flex items-center gap-3">
                <img
                  src={process.env.PUBLIC_URL + "/icons/icon_phone.svg"}
                  alt="phone"
                  width={20}
                  height={20}
                />
                <p>010-5958-0500</p>
              </div>
              <div className="flex items-start gap-3">
                <img
                  src={process.env.PUBLIC_URL + "/icons/icon_loaction.svg"}
                  alt="location"
                  width={20}
                  height={20}
                  className="mt-1"
                />
                <p>경기도 화성시 동탄영천로 150 현대실리콘엘리동탄C동 906호</p>
              </div>
            </div>
            <button
              type="button"
              className=" relative w-full lg:w-fit h-fit mt-auto bg-white py-2 px-10 lg:px-16 rounded-md text-[var(--point-color)]"
              onClick={() => navigate("/contact")}
            >
              <span className="absolute right-1.5 top-1 flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-200 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>
              <p className="font-black">문의하기</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
