//
//
//
//
//

export const categoryList = [
  {
    id: 1,
    title: "콘크리트 코어채취",
  },
  {
    id: 2,
    title: "콘크리트 파취 후 철근 채취",
  },
  {
    id: 3,
    title: "철근 부식도 조사",
  },
  {
    id: 4,
    title: "반발강도 측정 면 제거",
  },
  {
    id: 5,
    title: "코어작업",
  },
];
