import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const BottomQuick = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [contents, setContents] = useState("");
  const [loading, setLoading] = useState(false);

  const postContactHandler = async () => {
    if (!name || !phone) {
      alert("필수정보를 모두 입력해주세요.");
      return false;
    }

    if (phone.includes("010") && phone.length !== 11) {
      alert("연락처를 모두 입력해주세요.");
      return false;
    }

    await axios
      .post(`/api/contact/quick`, {
        name: name,
        phone: phone,
        contents: contents,
      })
      .then((res) => {
        if (res.data.result === "0000") {
          toast.success("문의접수가 완료되었습니다.", {
            autoClose: 2000,
            onClose: () => (window.location.href = "/transform"),
          });
        } else {
          toast.error(res.data.data, {
            autoClose: 5000,
            onClose: () => (window.location.href = "/"),
          });
        }
      })
      .catch((e) =>
        toast.error(e, {
          autoClose: 5000,
          onClose: () => (window.location.href = "/"),
        })
      );
  };

  return (
    <div className="hidden xl:inline-block fixed left-0 bottom-0 w-full h-fit bg-blue-900 text-white py-3 px-[calc((100%-var(--container))/2)] z-30">
      <div className=" relative w-full h-fit grid grid-cols-4 items-center gap-5">
        <button className=" relative full h-full flex items-center gap-2">
          <div className=" relative w-14 h-14">
            <img
              className=" relative w-full h-full object-contain"
              src={process.env.PUBLIC_URL + "/images/icon_call2.png"}
              alt="icon"
            />
          </div>
          <div className=" relative w-fit pt-2 text-left">
            <h4 className="leading-4 gmarket">010-5958-0500</h4>
            <small className=" text-xs">
              평일 09-20시 / 주말 및 공휴일 10시-19시
            </small>
          </div>
        </button>
        <div className=" col-span-3 relative w-full h-fit grid grid-cols-4 gap-2">
          <div className=" relative w-full h-full">
            <input
              type="text"
              id="name1"
              className="relative w-full !h-10 rounded-md text-xs !pl-12 pr-2"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="성함을 입력하세요."
            />
            <label
              htmlFor="name1"
              className="absolute left-2.5 top-2.5 text-sm text-[var(--font-color)]"
            >
              성함
            </label>
          </div>
          <div className=" relative w-full h-full">
            <input
              type="text"
              id="phone1"
              className="relative w-full !h-10 rounded-md text-xs !pl-[77px] pr-2"
              value={phone}
              onChange={(e) =>
                setPhone(e.target.value.replaceAll(/[^0-9.]/g, ""))
              }
              placeholder="전화번호를 입력하세요."
            />
            <label
              htmlFor="phone1"
              className="absolute left-2.5 top-2.5 text-sm text-[var(--font-color)]"
            >
              전화번호
            </label>
          </div>
          <div className=" relative w-full h-full">
            <input
              type="text"
              id="contents1"
              className="relative w-full !h-10 rounded-md text-xs !pl-12 pr-2"
              value={contents}
              onChange={(e) => setContents(e.target.value)}
              placeholder="내용을 입력하세요."
            />
            <label
              htmlFor="contents1"
              className="absolute left-2.5 top-2.5 text-sm text-[var(--font-color)]"
            >
              내용
            </label>
          </div>
          <div className=" relative w-full h-full grid grid-cols-3 items-center gap-2">
            <button
              type="button"
              disabled={loading}
              className=" col-span-2 relative w-full h-full bg-[var(--point-color)] rounded-md"
              onClick={() => {
                setLoading(true);
                postContactHandler();
              }}
            >
              <p>간편 견적 접수</p>
            </button>
            <label
              className="relative w-full h-fit text-xs text-left flex"
              htmlFor="privacy"
            >
              <input
                type="checkbox"
                name="privacy"
                id="privacy"
                className=" relative w-fit h-fit mt-0.5"
                defaultChecked={true}
              />
              &nbsp;개인정보처리방침동의
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomQuick;
