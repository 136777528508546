import {
  IconBrandTelegram,
  IconMessages,
  IconPinned,
  IconUserQuestion,
} from "@tabler/icons-react";

export const ADMIN_NAV_LIST = [
  {
    id: 1,
    title: "Inquiry",
    pathname: "/admin/inquiry",
    icon: IconMessages,
  },
  {
    id: 2,
    title: "Portfolio",
    pathname: "/admin/portfolio",
    icon: IconUserQuestion,
  },
];
