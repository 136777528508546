import axios from "axios";
import { categoryList } from "const/category";
import { dateFormat } from "const/date";
import { Board } from "const/table";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const APortfolioList = () => {
  const [data, setData] = useState<Board[] | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);

  useEffect(() => {
    axios
      .get(`/api/board?page=${page - 1}&size=12`)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
          setTotal(Math.ceil(res.data.data.totalElements / 12));
        }
      })
      .catch((e) => console.error(e));
  }, [page]);

  const deleteHandler = (id: number) => {
    if (!window.confirm("정말로 삭제하시겠습니까?")) return false;

    axios
      .delete(`/api/board/${id}`)
      .then((res) => {
        if (res.data.result === "0000") {
          toast.success("게시물 삭제가 완료되었습니다.", {
            autoClose: 2000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        } else {
          toast.error("다시 시도해주세요.", {
            autoClose: 5000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        }
      })
      .catch((e) =>
        toast.error(e, {
          autoClose: 5000,
          onClose: () => (window.location.href = "/admin/portfolio"),
        })
      );
  };
  return (
    <div className=" relative w-full h-full">
      <div className="flex justify-between items-center">
        <h4>주요실적</h4>
        <button
          type="button"
          onClick={() => (window.location.href = "/admin/portfolio/write")}
        >
          <div className=" flex items-center gap-1">
            <small>추가</small>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="tabler-icon tabler-icon-circle-plus "
            >
              <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
              <path d="M9 12h6"></path>
              <path d="M12 9v6"></path>
            </svg>
          </div>
        </button>
      </div>
      <div className=" relative w-full h-full my-10 border-t">
        <div className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2">
          <p>번호</p>
          <p className="col-span-3">제목</p>
          <p className="col-span-3">카테고리</p>
          <p>조회수</p>
          <p className="col-span-2">작성일</p>
          <p>삭제</p>
        </div>
        {data &&
          data.length > 0 &&
          data.map((v, i) => (
            <div
              key={i}
              onClick={() =>
                (window.location.href = `/admin/portfolio/${v.id}`)
              }
              className=" relative w-full h-full grid grid-cols-11 text-center border-b py-3 px-2 cursor-pointer hover:bg-[var(--gray-color)]"
            >
              <p>{v.id}</p>
              <p className="col-span-3 ellipsis">{v.title}</p>
              <p className="col-span-3 ellipsis">
                {categoryList
                  .filter((cate) => cate.id === v.categoryId)
                  .map((cate) => cate.title)}
              </p>
              <p>{v.clickCount}</p>
              <p className="col-span-2 ellipsis">{dateFormat(v.created)}</p>
              <p
                onClick={() => deleteHandler(v.id)}
                className=" relative w-fit h-fit mx-auto"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="tabler-icon tabler-icon-trash-x "
                >
                  <path d="M4 7h16"></path>
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                  <path d="M10 12l4 4m0 -4l-4 4"></path>
                </svg>
              </p>
            </div>
          ))}
      </div>
      <div
        id="pagination"
        className=" relative flex justify-center items-center gap-10"
      >
        <button
          type="button"
          className=" relative w-3 h-3"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          <img
            src="/icons/arrow.svg"
            alt="icon"
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
        <div className=" flex gap-5">
          {[...Array(total)].map((v, i) => (
            <button
              key={i + 1}
              onClick={() => setPage(i + 1)}
              type="button"
              className={`relative w-fit h-fit ${
                page === i + 1 ? "text-[var(--point-color)]" : ""
              } hover:text-[var(--point-color)] transition-all duration-300 cursor-pointer`}
            >
              <p>{i + 1}</p>
            </button>
          ))}
        </div>
        <button
          type="button"
          className=" relative w-3 h-3"
          disabled={page === total}
          onClick={() => setPage(page + 1)}
        >
          <img src="/icons/arrow.svg" alt="icon" />
        </button>
      </div>
    </div>
  );
};

export default APortfolioList;
