import { IconLayoutDashboard } from "@tabler/icons-react";
import { ADMIN_NAV_LIST } from "const/adminTab";
import { useLocation } from "react-router-dom";

const ANav = () => {
  const { pathname } = useLocation();
  return (
    <div className="fixed -left-full lg:left-0 top-0 min-w-[270px] w-[270px] h-full min-h-screen bg-white text-[#5A6A85] border-r transition-all duration-200 z-50">
      <div className="px-6">
        <div
          onClick={() => (window.location.href = "/admin/dashboard")}
          className="relative w-auto max-h-16 lg:max-h-24 mt-10 overflow-hidden"
        >
          <div>
            <img
              className="!relative w-auto h-full max-h-16 lg:max-h-24"
              alt="logo"
              src={"/images/logo.png"}
              width={943}
              height={119}
            />
          </div>
        </div>
        <div className="relative mt-12">
          <small className="text-xs px-2">HOME</small>
          <button
            onClick={() => (window.location.href = "/admin/dashboard")}
            className={
              pathname === "/admin/dashboard"
                ? "relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-[var(--point-color)] text-white rounded-xl transition-all duration-200"
                : "relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-[var(--point-color)] hover:text-white hover:bg-opacity-10 rounded-xl transition-all duration-200"
            }
          >
            <IconLayoutDashboard size={22} stroke={1.5} />
            <small className="text-sm">Dashboard</small>
          </button>
        </div>
        <div className="relative my-5">
          <small className="text-xs px-2">DATA</small>
          {ADMIN_NAV_LIST.map((v, i) => (
            <button
              key={i}
              onClick={() => (window.location.href = v.pathname)}
              className={
                pathname.includes(v.pathname)
                  ? "relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 bg-[var(--point-color)] text-white rounded-xl transition-all duration-200"
                  : "relative w-full h-fit py-3 flex gap-3 items-center px-2 my-1 hover:bg-[var(--point-color)] hover:text-white hover:bg-opacity-10 rounded-xl transition-all duration-200"
              }
            >
              <v.icon size={22} stroke={1.5} />
              <small className="text-sm">{v.title}</small>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ANav;
