import { useState } from "react";

import DaumPostcode from "react-daum-postcode";

import SubTitle from "components/common/sub/SubTitle";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [address, setaddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [type, setType] = useState("");
  const [coreAndMm, setCoreAndMm] = useState("");
  const [coreType, setCoreType] = useState("");
  const [workNeed, setWorkNeed] = useState("");
  const [contents, setContents] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const themeObj = {
    bgColor: "#FFFFFF",
    pageBgColor: "#FFFFFF",
    postcodeTextColor: "#C05850",
    emphTextColor: "#222222",
  };

  const postCodeStyle = {
    width: "400px",
    height: "480px",
    fontSize: "10pt",
  };

  const completeHandler = (data: { address: string; zonecode: string }) => {
    const { address, zonecode } = data;
    setZipcode(zonecode);
    setaddress(address);
  };

  const closeHandler = (state: string) => {
    if (state === "FORCE_CLOSE") {
      setIsOpen(false);
    } else if (state === "COMPLETE_CLOSE") {
      setIsOpen(false);
    }
  };

  const postContactHandler = async () => {
    if (!name || !phone) {
      alert("필수정보를 모두 입력해주세요.");
      return false;
    }

    if (phone.includes("010") && phone.length !== 11) {
      alert("연락처를 모두 입력해주세요.");
      return false;
    }

    await axios
      .post(`/api/contact`, {
        name: name,
        phone: phone,
        email: email,
        type: type,
        zipcode: zipcode,
        address: address,
        addressDetail: addressDetail,
        coreAndMm: coreAndMm,
        coreType: coreType,
        workNeed: workNeed,
        contents: contents,
      })
      .then((res) => {
        if (res.data.result === "0000") {
          toast.success("문의접수가 완료되었습니다.", {
            autoClose: 2000,
            onClose: () => (window.location.href = "/transform"),
          });
        } else {
          toast.error(res.data.data, {
            autoClose: 5000,
            onClose: () => (window.location.href = "/contact"),
          });
        }
      })
      .catch((e) =>
        toast.error(e, {
          autoClose: 5000,
          onClose: () => (window.location.href = "/contact"),
        })
      );
  };

  return (
    <div className=" relative w-full h-full">
      <SubTitle
        category="온라인문의"
        title="온라인문의"
        src="/images/sub12.jpg"
      />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div className=" relative w-full h-full grid gap-5 my-5 lg:my-10">
            <h3>문의 내용</h3>
            <div className="line mb-3"></div>
            <div className=" relative w-full h-full grid grid-cols-1 grid-rows-4 lg:grid-cols-2 lg:grid-rows-2 gap-5 lg:gap-10">
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="name2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>담당자명</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="name2"
                  id="name2"
                  className="mt-2 lg:mt-0 !pl-20"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="phone2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>전화번호</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="phone2"
                  id="phone2"
                  className="mt-2 lg:mt-0 !pl-20"
                  value={phone}
                  maxLength={11}
                  onChange={(e) =>
                    setPhone(e.target.value.replaceAll(/[^0-9.]/g, ""))
                  }
                />
              </div>
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="email2"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>이메일</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="email2"
                  id="email2"
                  className="mt-2 lg:mt-0 !pl-16"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className=" relative w-full h-full lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="type1"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>용도 및 구조</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="type1"
                  id="type1"
                  className="mt-2 lg:mt-0 !pl-28"
                  value={type}
                  maxLength={11}
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
            </div>
            <div className=" relative w-full h-fit grid gap-3 my-2 lg:my-5">
              <div className=" relative w-full max-w-screen-sm grid grid-cols-4 gap-5">
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  disabled
                  value={zipcode}
                  placeholder="우편번호"
                  className=" col-span-3"
                />
                <button
                  type="button"
                  className=" relative w-full h-full px-5 rounded-sm bg-[var(--gray-color)]"
                  onClick={() => setIsOpen(true)}
                >
                  <p className="font-black">검색</p>
                </button>
              </div>
              <input
                type="text"
                name="address"
                id="address"
                value={address}
                disabled
                placeholder="기본주소"
              />
              <input
                type="text"
                name="addressDetail"
                id="addressDetail"
                value={addressDetail}
                onChange={(e) => setAddressDetail(e.target.value)}
                placeholder="동수 및 층수를 입력해주세요."
              />
            </div>
            <div className=" relative w-full h-fit lg:grid grid-cols-2 gap-5 lg:gap-10">
              <div className=" relative w-full h-fit lg:flex items-center gap-5">
                <div className="absolute left-2 top-0 w-fit h-full grid items-center z-10 ">
                  <label
                    htmlFor="coreAndMm"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>코어 공수</h5>
                  </label>
                </div>
                <input
                  type="text"
                  name="coreAndMm"
                  id="coreAndMm"
                  className="mt-2 lg:mt-0 !pl-[90px]"
                  value={coreAndMm}
                  onChange={(e) => setCoreAndMm(e.target.value)}
                  placeholder="코어 공수(개수) / 직경(mm)"
                />
              </div>
              <div className=" relative w-full h-full lg:grid grid-cols-2">
                <div className=" relative w-full h-fit my-5 lg:my-0">
                  <h5 className="font-black">코어 방법</h5>
                  <div className=" relative w-full h-fit flex items-center gap-5">
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="coreType"
                        id="coreType1"
                        value={"코어 건식"}
                        onChange={(e) => setCoreType(e.target.value)}
                      />
                      <label htmlFor="coreType1">코어 건식</label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="coreType"
                        id="coreType2"
                        value={"코어 습식"}
                        onChange={(e) => setCoreType(e.target.value)}
                      />
                      <label htmlFor="coreType2">코어 습식</label>
                    </div>
                  </div>
                </div>
                <div className=" relative w-full h-fit mb-5 lg:mb-0">
                  <h5 className="font-black">고소작업</h5>
                  <div className=" relative w-full h-fit flex items-center gap-5">
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="workNeed"
                        id="workNeed1"
                        value={"고소작업 없음"}
                        onChange={(e) => setWorkNeed(e.target.value)}
                      />
                      <label htmlFor="workNeed1">고소작업 없음</label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="workNeed"
                        id="workNeed2"
                        value={"고소작업 필요"}
                        onChange={(e) => setWorkNeed(e.target.value)}
                      />
                      <label htmlFor="workNeed2">고소작업 필요</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2 relative w-full h-fit">
                <div className="absolute left-2 top-2 w-fit h-full z-10 ">
                  <label
                    htmlFor="contents"
                    className=" relative w-fit h-fit font-black"
                  >
                    <h5>기타 문의 사항</h5>
                  </label>
                </div>
                <textarea
                  name="contents"
                  id="contents"
                  cols={30}
                  rows={10}
                  value={contents}
                  onChange={(e) => setContents(e.target.value)}
                  className="pt-10"
                ></textarea>
              </div>
              <div className="col-span-2 relative w-full h-fit flex items-center gap-5">
                <h5 className="font-black">약관 동의</h5>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="privacy"
                    id="privacy"
                    className=" relative w-4 h-4"
                    checked={privacy}
                    onChange={(e) => setPrivacy(!privacy)}
                  />
                  <label htmlFor="privacy">
                    개인정보처리방침에 동의합니다.
                  </label>
                </div>
              </div>
            </div>
            <div className=" relative w-fit h-fit my-5 lg:my-10 mx-auto">
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  postContactHandler();
                }}
                className=" relative w-fit h-fit py-1 px-10 lg:py-3 lg:px-32 bg-[var(--point-color)] text-white rounded-full hover:shadow-lg"
              >
                <h4>문의하기</h4>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="fixed left-0 top-0 w-full h-full z-50">
          <div
            onClick={() => setIsOpen(false)}
            className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-45"
          ></div>
          <div className=" relative w-fit h-fit bg-white px-2 pt-7 mx-auto mt-24">
            <DaumPostcode
              theme={themeObj}
              style={postCodeStyle}
              onComplete={completeHandler}
              onClose={closeHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
