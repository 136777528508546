import axios from "axios";
import { Contact } from "const/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AInquiryDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [data, setData] = useState<Contact | null>(null);

  useEffect(() => {
    axios
      .get(`/api/contact/${params.id}`)
      .then((res) => {
        if (res.data.result === "0000") setData(res.data.data);
        else
          toast.error(res.data.data, {
            onClose: () => navigate(-1),
          });
      })
      .catch((e) =>
        toast.error(e, {
          onClose: () => navigate(-1),
        })
      );
  }, [params.id]);
  return (
    <div className=" relative w-full h-full">
      <div className="flex justify-between items-center">
        <h4>온라인문의</h4>
      </div>
      {data && (
        <div className=" relative w-full h-full my-10 border p-5 lg:p-10 grid grid-cols-2 gap-10 shadow-md">
          <div className=" relative w-full h-full grid grid-cols-2 gap-2 border-r">
            <p>고객명</p>
            <p>{data.name}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2">
            <p>전화번호</p>
            <p>{data.phone}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2 border-r">
            <p>이메일</p>
            <p>{data.email}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2">
            <p>용도 및 구조</p>
            <p>{data.type}</p>
          </div>
          <div className="col-span-2 relative w-full h-full grid grid-cols-4 gap-2 ">
            <p>주소</p>
            <p className="col-span-3 !overflow-visible !whitespace-normal !break-normal">
              {data.address && `${data.address}, ${data.adrDetail}`}
            </p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2 border-r">
            <p>코어 공수</p>
            <p>{data.coreAndMm}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2">
            <p>코어 방법</p>
            <p>{data.coreType}</p>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 gap-2">
            <p>고소 작업</p>
            <p>{data.workNeed}</p>
          </div>
          <div className="col-span-2 relative w-full h-full grid grid-cols-4 gap-2 ">
            <p>문의내용</p>
            <p className="col-span-3 !overflow-visible !whitespace-normal !break-normal">
              {data.contents}
            </p>
          </div>
        </div>
      )}
      <div className="relative w-full h-fit max-w-96 mx-auto my-7 lg:my-14">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className=" relative w-full h-fit bg-[var(--gray-color)] py-3 rounded-sm"
        >
          <p className="font-black">목록으로</p>
        </button>
      </div>
    </div>
  );
};

export default AInquiryDetail;
