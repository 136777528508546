import axios from "axios";
import SubTitle from "components/common/sub/SubTitle";
import { categoryList } from "const/category";
import { Board } from "const/table";
import { useEffect, useState } from "react";

const Portfolio = () => {
  const [category, setCategory] = useState(0);
  const [data, setData] = useState<Board[] | null>(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    axios
      .get(`/api/board?page=0&size=12`)
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
          setTotal(Math.ceil(res.data.data.totalElements / 12));
        }
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    axios
      .get(
        category === 0
          ? `/api/board?page=${page - 1}&size=12`
          : `/api/board/category?page=${page - 1}&size=12&category=${category}`
      )
      .then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data.content);
          setTotal(Math.ceil(res.data.data.totalElements / 12));
        }
      })
      .catch((e) => console.error(e));
  }, [category, page]);
  return (
    <div className=" relative w-full h-full">
      <SubTitle category="주요실적" title="주요실적" src="/images/sub11.jpg" />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div className=" relative w-full h-fit grid grid-cols-2 lg:grid-cols-3 text-center cursor-pointer">
            <div
              onClick={() => {
                setPage(1);
                setCategory(0);
              }}
              className={
                category === 0
                  ? "relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
                  : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
              }
            >
              <p>전체보기</p>
            </div>
            {categoryList.map((v, i) => (
              <div
                key={i}
                onClick={() => {
                  setPage(1);
                  setCategory(v.id);
                }}
                className={
                  category === v.id
                    ? "relative w-full h-full py-2 bg-[var(--point-color)] text-white px-3"
                    : " relative w-full h-full py-2 bg-[var(--gray-color)] hover:bg-[var(--point-color)] hover:text-white transition-all duration-300 cursor-pointer px-3"
                }
              >
                <p>{v.title}</p>
              </div>
            ))}
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 lg:grid-cols-4 gap-5 my-5 lg:my-10 pb-10">
            {data &&
              data.length > 0 &&
              data.map((v, i) => (
                <div
                  data-aos="fade-up"
                  key={i}
                  className="mb-3 cursor-pointer aos-init aos-animate"
                  onClick={() => (window.location.href = `/portfolio/${v.id}`)}
                >
                  <div className=" relative w-full h-44 lg:h-52 xl:h-72 bg-gray-200">
                    <div className=" absolute left-0 top-0 w-full h-full grid items-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-all duration-300 z-10">
                      <div className=" text-white relative w-fit h-fit mx-auto animate-bounce">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          stroke="none"
                          className="tabler-icon tabler-icon-pointer-filled "
                        >
                          <path d="M3.039 4.277l3.904 13.563c.185 .837 .92 1.516 1.831 1.642l.17 .016a2.2 2.2 0 0 0 1.982 -1.006l.045 -.078l1.4 -2.072l4.05 4.05a2.067 2.067 0 0 0 2.924 0l1.047 -1.047c.388 -.388 .606 -.913 .606 -1.461l-.008 -.182a2.067 2.067 0 0 0 -.598 -1.28l-4.047 -4.048l2.103 -1.412c.726 -.385 1.18 -1.278 1.053 -2.189a2.2 2.2 0 0 0 -1.701 -1.845l-13.524 -3.89a1 1 0 0 0 -1.236 1.24z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className=" absolute right-2 bottom-2 w-fit h-fit z-20">
                      <small className="flex items-center gap-1 font-black text-white">
                        {categoryList
                          .filter((cate) => cate.id === v.categoryId)
                          .map((cate) => cate.title)}
                      </small>
                    </div>
                    <img
                      className=" relative w-full h-full object-cover object-center"
                      src={
                        v.thumb
                          ? v.thumb
                          : v.images && v.images.length > 0
                          ? v.images[0].src
                          : "/images/business75.jpg"
                      }
                      alt="thumb"
                    />
                  </div>

                  <div className=" relative w-full h-fit p-4 bg-[var(--light-color)]">
                    <p>{v.title}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className=" relative flex justify-center items-center gap-10">
            <button
              type="button"
              className=" relative w-3 h-3"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              <img
                src="/icons/arrow.svg"
                alt="icon"
                style={{ transform: "rotate(180deg)" }}
              />
            </button>
            <div className=" flex gap-5">
              {[...Array(total)].map((v, i) => (
                <button
                  key={i + 1}
                  onClick={() => setPage(i + 1)}
                  type="button"
                  className={`relative w-fit h-fit ${
                    page === i + 1 ? "text-[var(--point-color)]" : ""
                  } hover:text-[var(--point-color)] transition-all duration-300 cursor-pointer`}
                >
                  <p>{i + 1}</p>
                </button>
              ))}
            </div>
            <button
              type="button"
              className=" relative w-3 h-3"
              disabled={page === total}
              onClick={() => setPage(page + 1)}
            >
              <img src="/icons/arrow.svg" alt="icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
