import axios from "axios";
import CkEditor from "components/admin/CkEditor ";
import { categoryList } from "const/category";
import { Board } from "const/table";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const APortfolioDetail = () => {
  const params = useParams();
  const [data, setData] = useState<Board | null>(null);

  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState(1);
  const [contents, setContents] = useState("");

  const [file, setFile] = useState<{ name: string; file: File | null }>({
    name: "",
    file: null,
  });

  let imgArr: string[] = [];

  useEffect(() => {
    params.id &&
      axios.get(`/api/board/${params.id}`).then((res) => {
        if (res.data.result === "0000") {
          setData(res.data.data);
          setTitle(res.data.data.title);
          setCategoryId(res.data.data.categoryId);
          setContents(res.data.data.contents);
          setFile({ name: res.data.data.thumb, file: null });
        }
      });
  }, [params.id]);

  useEffect(() => {
    axios.get(`/api/images/init-data`).then((res) => {
      console.log(res.data);
    });
  }, []);

  const boardUpdateHandler = async () => {
    let images = document.querySelectorAll<HTMLImageElement>(".contents img");

    console.log("images: " + images[0]);

    for (var i = 0; i < images.length; i++) {
      console.log("img: " + images[i].src);
      imgArr.push(images[i].src);
    }

    console.log(imgArr.toString());

    const formData = new FormData();
    params.id && formData.append("id", params.id);
    formData.append("title", title);
    formData.append("contents", contents);
    formData.append("adminId", sessionStorage.getItem("id")!);
    formData.append("categoryId", categoryId.toString());

    file.file && formData.append("file", file.file);

    await axios
      .put(`/api/board`, formData)
      .then((res) => {
        if (res.data.result === "0000") {
          toast.success("게시물 수정이 완료되었습니다.", {
            autoClose: 2000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        } else {
          toast.error("다시 시도해주세요.", {
            autoClose: 5000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        }
      })
      .catch((e) =>
        toast.error(e, {
          autoClose: 5000,
          onClose: () => (window.location.href = "/admin/portfolio"),
        })
      );
  };

  const boardPostHandler = async () => {
    let images = document.querySelectorAll<HTMLImageElement>(".contents img");

    console.log("images: " + images[0]);

    for (var i = 0; i < images.length; i++) {
      console.log("img: " + images[i].src);
      imgArr.push(images[i].src);
    }

    console.log(imgArr.toString());

    const formData = new FormData();
    params.id && formData.append("id", params.id);
    formData.append("title", title);
    formData.append("contents", contents);
    formData.append("adminId", sessionStorage.getItem("id")!);
    formData.append("categoryId", categoryId.toString());

    file.file && formData.append("file", file.file);

    await axios
      .post(`/api/board`, formData)
      .then((res) => {
        if (res.data.result === "0000") {
          toast.success("게시물 등록이 완료되었습니다.", {
            autoClose: 2000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        } else {
          toast.error("다시 시도해주세요.", {
            autoClose: 5000,
            onClose: () => (window.location.href = "/admin/portfolio"),
          });
        }
      })
      .catch((e) =>
        toast.error(e, {
          autoClose: 5000,
          onClose: () => (window.location.href = "/admin/portfolio"),
        })
      );
  };

  return (
    <div className=" relative w-full h-full">
      <div className="flex justify-between items-center">
        <h4>주요실적 등록</h4>
      </div>
      <div className=" relative w-full h-full my-10">
        <input
          type="text"
          name="title"
          id="title"
          className="!h-12"
          placeholder="제목을 입력해주세요."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div id="upload" className="relative w-full h-fit">
          <div className="py-5">
            <p className="pl-2">
              <b>썸네일</b>
            </p>
            <input
              className="upload-name border h-fit py-2.5 px-2 my-2"
              value={(file && file.name) || "첨부파일"}
              placeholder="첨부파일"
              readOnly
            />
            <label htmlFor="file" className="ml-2 relative w-fit h-fit px-5 py-3 border cursor-pointer">
              파일찾기
            </label>
            <input
              type="file"
              id="file"
              className="hidden"
              defaultValue={""}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => {
                e.target.files &&
                  setFile({
                    name: e.target.files[0].name,
                    file: e.target.files[0],
                  });
              }}
            />
          </div>
        </div>
        <div className=" relative w-full h-fit grid grid-cols-3 lg:grid-cols-5 grid-rows-2 lg:grid-rows-1 text-center my-5">
          {categoryList.map((v, i) => (
            <button
              key={i}
              onClick={() => setCategoryId(v.id)}
              className={
                categoryId === v.id
                  ? "relative w-full h-full border py-2 border-[var(--point-color)]"
                  : "relative w-full h-full border py-2"
              }
            >
              <small>{v.title}</small>
            </button>
          ))}
        </div>
        <div id="contents" className=" contents relative w-full h-full">
          <CkEditor data={contents} setData={setContents} />
        </div>
      </div>
      <div className="relative w-full h-fit max-w-96 mx-auto">
        {data ? (
          <button
            type="button"
            onClick={boardUpdateHandler}
            className=" relative w-full h-fit bg-[var(--point-color)] py-3 text-white rounded-sm"
          >
            <p className="font-black">게시글 수정</p>
          </button>
        ) : (
          <button
            type="button"
            onClick={boardPostHandler}
            className=" relative w-full h-fit bg-[var(--point-color)] py-3 text-white rounded-sm"
          >
            <p className="font-black">게시글 등록</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default APortfolioDetail;
