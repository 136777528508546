import axios from "axios";
import BoardList from "components/admin/BoardList";
import InquiryList from "components/admin/InquiryList";
import { dateFormat } from "const/date";
import { useEffect, useState } from "react";

const Dashboard = () => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    axios.get(`/api/contact/count`).then((res) => setTotal(res.data.data));
  }, []);

  return (
    <div className=" relative w-full h-full">
      <div className="flex gap-2">
        <small className="bold text-sm">TODAY</small>
        <small className="text-sm">{dateFormat(Date())}</small>
      </div>
      <div className="relative w-full h-fit bg-white border shadow-md py-5 px-4 rounded-lg cursor-pointer my-3">
        <small className="text-base">문의글 등록 수</small>
        <div className="text-center">
          <h5>총 {total}개</h5>
        </div>
      </div>
      <div className=" relative w-full h-full grid lg:grid-cols-2 gap-5">
        <InquiryList />
        <BoardList />
      </div>
    </div>
  );
};

export default Dashboard;
