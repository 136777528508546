const Banner = () => {
  return (
    <div className=" relative w-[100vw] h-[calc((var(--vh,1vh)*100)-50px)] lg:h-[100vh]">
      <img
        alt="banner"
        src="/images/banner.jpg"
        className="  absolute left-0 top-0 w-full h-full object-cover object-center rotate-180"
      />
      <div className=" absolute left-0 top-[calc((var(--vh,1vh)*100)/4)] w-full h-fit text-white px-[calc((100%-var(--container))/2)]">
        <div className="relative w-full h-fit max-w-[var(--container-width)] mx-auto">
          <h5 className="font-black delay-[0.5s] opacity-0 blur animate-[blur-text-in_0.8s_forwards_cubic-bezier(0.11,0,0.5,0)]">
            Interior & Exterior
          </h5>
          <h2 className="gmarket font-black opacity-0 blur animate-[blur-text-in_0.8s_forwards_cubic-bezier(0.11,0,0.5,0)]">
            안전, 품질, 신뢰를 바탕으로
          </h2>
          <h2 className=" gmarket font-black delay-[0.3s] opacity-0 blur animate-[blur-text-in_0.8s_forwards_cubic-bezier(0.11,0,0.5,0)]">
            고객과 소통하는 전문업체 내외이앤씨
          </h2>
        </div>
      </div>
      <div className="absolute left-0 bottom-10 w-full h-fit">
        <div className=" relative w-fit h-fit mx-auto max-w-10 animate-bounce">
          <img alt="mouse" src={"/images/mouse.svg"} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
