import React from "react";
import { Map, MapMarker, useKakaoLoader } from "react-kakao-maps-sdk";

const KakaoMap = () => {
  const [loading, error] = useKakaoLoader({
    appkey: "eac84ba095c79772e98c665d1adb452f",
  });
  return (
    <Map // 지도를 표시할 Container
      center={{
        // 지도의 중심좌표
        lat: 37.2161467,
        lng: 127.1017635,
      }}
      style={{
        // 지도의 크기
        width: "100%",
        height: "100%",
      }}
      level={3} // 지도의 확대 레벨
    >
      <MapMarker
        image={{
          src: process.env.PUBLIC_URL + "/images/marker.png",
          size: {
            width: 180,
            height: 105,
          },
        }}
        position={{ lat: 37.2161467, lng: 127.1017635 }}
      ></MapMarker>
    </Map>
  );
};

export default KakaoMap;
