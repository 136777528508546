import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import MHeader from "./header/MHeader";
import MFooter from "./footer/MFooter";
import RightQuick from "./quick/RightQuick";
import BottomQuick from "./quick/BottomQuick";
import AHeader from "./header/AHeader";
import ANav from "./header/ANav";

const Layout = () => {
  const { pathname } = useLocation();

  if (pathname.includes("login")) {
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <Outlet />
      </div>
    );
  }

  //   if (
  //     pathname.includes("admin") &&
  //     (sessionStorage.getItem("email") === undefined ||
  //       sessionStorage.getItem("email") === null)
  //   ) {
  //     return (
  //       <div className=" relative w-full h-screen grid items-center justify-center">
  //         <div>
  //           <p className="mb-5">관리자 로그인이 필요합니다.</p>
  //           <div
  //             onClick={() => (window.location.href = "/admin/login")}
  //             className="relative w-fit h-fit py-1.5 px-10 border border-black mx-auto cursor-pointer hover:bg-black hover:text-white"
  //           >
  //             <p className="lg:text-base">관리자 로그인</p>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  if (pathname.includes("admin")) {
    return (
      <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
        <ANav />
        <AHeader />
        <div className="relative w-full h-full py-32 lg:py-36 px-2 lg:pl-72 lg:pr-[calc((100%-var(--container))/2)] max-w-[var(--container-width)] ">
          <Outlet />
        </div>
      </div>
    );
  }
  return (
    <div className="relative w-full h-full max-w-[100vw] overflow-x-hidden">
      <MHeader />
      <Outlet />
      <RightQuick />
      <BottomQuick />
      <MFooter />
    </div>
  );
};

export default Layout;
