import SubTitle from "components/common/sub/SubTitle";

const Equipment = () => {
  const equipArr = [
    {
      id: 1,
      src: "equip1.jpg",
      title: "철근탐사기",
      model: "PS-200",
      use: "콘크리트내 철근 위치 및 깊이 탐사",
    },
    {
      id: 2,
      src: "equip2.jpg",
      title: "코어채취기 (습식)",
      model: "DD 120",
      use: "콘크리트 코어 채취",
    },
    {
      id: 3,
      src: "equip3.jpg",
      title: "코어채취기 (습식)",
      model: "DD 350",
      use: "콘크리트 코어 채취",
    },
    {
      id: 4,
      src: "equip4.jpg",
      title: "코어채취기 (건식)",
      model: "DD2-160 EX",
      use: "콘크리트 코어 채취",
    },
    {
      id: 5,
      src: "equip5.jpg",
      title: "진공청소기",
      model: "VC 40L-X",
      use: "습식/건식 집진기",
    },
    {
      id: 6,
      src: "equip6.jpg",
      title: "월 체이서",
      model: "DCH 150-SL",
      use: "콘크리트 홈파기",
    },
    {
      id: 7,
      src: "equip7.jpg",
      title: "그라인더",
      model: "DGH 130",
      use: "콘크리트 면 갈이",
    },
    {
      id: 8,
      src: "equip8.jpg",
      title: "해머드릴 뿌레카",
      model: "XZC02-45",
      use: "콘크리트 파쇄기",
    },
  ];
  return (
    <div className=" relative w-full h-full">
      <SubTitle
        category="회사소개"
        title="장비보유현황"
        src="/images/sub2.jpg"
      />
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] py-14 lg:py-28">
        <div className=" relative w-full h-full max-w-[var(--container-width)] mx-auto">
          <div id="title">
            <div className=" relative w-8 h-1 bg-[var(--point-color)] mb-5"></div>
            <p className="font-black opacity-55">Equipments</p>
            <h3>내외이앤씨의 최신 장비를 소개해드립니다.</h3>
          </div>
          <div className=" relative w-full h-full grid grid-cols-2 grid-rows-4 lg:grid-cols-3 lg:grid-rows-3 gap-5 lg:gap-10 my-5 lg:my-10">
            {equipArr.map((v, i) => (
              <div key={i} className=" relative w-full h-full mb-5 lg:mb-2">
                <div className=" relative w-full h-56 lg:h-96 overflow-hidden">
                  <img
                    className="relative w-full h-full object-contain object-center lg:hover:scale-110 transition-transform duration-700"
                    src={`/images/${v.src}`}
                    alt="equipment"
                  />
                </div>
                <div className="  relative w-full h-fit lg:px-2 py-3 mb-2 border-b">
                  <p className="font-black">{v.title}</p>
                </div>
                <div className=" relative w-full h-fit lg:px-2">
                  <small>✔️ 모델명 : {v.model}</small>
                  <br />
                  <small>✔️ {v.use}</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipment;
