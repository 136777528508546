import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Home from "./pages/home";
import Layout from "./components/common/Layout";
import Greetings from "./pages/about/greetings";
import Equipment from "./pages/about/equipment";
import Location from "./pages/about/location";

import Business1 from "pages/business/1";
import Business2 from "pages/business/2";
import Business3 from "pages/business/3";
import Business4 from "pages/business/4";
import Business5 from "pages/business/5";
import Portfolio from "pages/portfolio";
import Contact from "pages/contact";
import Business6 from "pages/business/6";
import Business7 from "pages/business/7";
import Transform from "pages/transform";
import PortfolioDetail from "pages/portfolio/[id]";
import Login from "pages/admin/login";
import Dashboard from "pages/admin/dashboard";
import AInquiryList from "pages/admin/inquiry";
import APortfolioList from "pages/admin/portfolio";
import AInquiryDetail from "pages/admin/inquiry/[id]";
import APortfolioDetail from "pages/admin/portfolio/[id]";

function App() {
  function vh_height() {
    var vh = window.outerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  useEffect(() => {
    vh_height();
    AOS.init({
      delay: 50,
      duration: 400,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div className="App">
      <ToastContainer autoClose={3000} />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          <Route path="about">
            <Route path="greetings" element={<Greetings />} />
            <Route path="equipments" element={<Equipment />} />
            <Route path="location" element={<Location />} />
          </Route>

          <Route path="business">
            <Route path="1" element={<Business1 />} />
            <Route path="2" element={<Business2 />} />
            <Route path="3" element={<Business3 />} />
            <Route path="4" element={<Business4 />} />
            <Route path="5" element={<Business5 />} />
            <Route path="6" element={<Business6 />} />
            <Route path="7" element={<Business7 />} />
          </Route>

          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:id" element={<PortfolioDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/transform" element={<Transform />} />

          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/inquiry" element={<AInquiryList />} />
          <Route path="/admin/inquiry/:id" element={<AInquiryDetail />} />
          <Route path="/admin/portfolio" element={<APortfolioList />} />
          <Route path="/admin/portfolio/:id" element={<APortfolioDetail />} />
          <Route path="admin/portfolio/write" element={<APortfolioDetail />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
